:root {--main-theme-color:#195dd4;--main-primary-color:#000000;}
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,500i,600,700');
@font-face {
    font-family: 'MuliRegular';
    src: url('../fonts/MuliRegular.woff2') format('woff2'),
         url('../fonts/MuliRegular.woff') format('woff');
    font-weight: normal;    
    font-style: normal;
}
@font-face {
    font-family: 'MuliBold';
    src: url('../fonts/MuliBold.woff2') format('woff2'),
         url('../fonts/MuliBold.woff') format('woff');
    font-weight: normal;    
    font-style: normal;
}
body{font-family:'Poppins', sans-serif;}
a{cursor: pointer;}
.no-padding {padding: 0px !important;}
.no-padding-left {padding-left: 0px !important;}
.no-padding-right {padding-right: 0px !important;}
.no-margin {margin: 0px !important;}
.no-margin-left {margin-left: 0px !important;}
.no-margin-right {margin-right: 0px !important;}
.wrapper-login { float: left;width: 100%;background:#fff; position: relative;}
.wrapper-payment{ float: left;width: 100%;background:#fff; position: relative; height:auto;}
.tvr-sign-in-btn {font-size: 18px; color: #fff; font-weight: 500;}
.forget-password{float:left;margin:25px 0;}
.forget-password a{font-size:14px;font-weight:400;}
.tvr-input{color:var(--main-primary-color) !important;font-size:14px;font-family: "MuliRegular";height: 45px; padding-left: 0px;border-radius: 0;box-shadow: none;padding-right: 0;border-top: 0;border-right: 0;border-left: 0;border-bottom: 1px solid #dbdbdb;}
.tvr-input:focus{box-shadow: none;}
.tvr-input::placeholder{opacity: 0.7;color:var(--main-primary-color);}
.tvr-input_regt{color:#000!important;font-size:14px;font-family: "MuliRegular";height: 45px; padding-left: 15px;border-radius: 0;box-shadow: none;padding-right: 0;border-top: 0;border-right: 0;border-left: 0;border-bottom: 1px solid #dbdbdb;}
.tvr-input_regt:focus{box-shadow: none;}
.tvr-input_num{color:#000!important;font-size:14px;font-family: "MuliRegular";height: 45px; padding-left: 15px;border-radius: 0;box-shadow: none;padding-right: 0;border-top: 0;border-right: 0;border-left: 0;border-bottom: 1px solid #dbdbdb;}
.tvr-input_num:focus{box-shadow: none;}
.margin-bottom-40{margin-bottom:40px;}
.margin-top-5{margin-top:5px!important;}
.margin-40{margin:40px 0;}
.margin-top-80{margin-top:80px;}
.color-black{color:#000!important;}
.tvr-main-wrapper{float: left;width: 100%;padding: 20px 0 0px 0}
.tvr-login-wrap{float: left;width: 100%;border: 1px solid #e9e9e9;border-bottom:0;position: relative;margin:0;}
.tvr-form-label{color: #434c5e;display: block; font-size: 11px;font-weight: 700;letter-spacing: 0.8px;margin-bottom: 8px;position: relative;text-transform: uppercase;}
.tvr-forgot-password {color: #969696;cursor: pointer;font-size: 11px;font-weight: normal;position: absolute;right: 0;text-transform: none;top: 0;transition: color 0.2s ease 0s;}
.tvr-forgot-password:hover{color: #000;}
.tvr-login-head{float: left;width: 100%; padding:30px;background-color:var(--main-theme-color);position: relative;}
.tvr-login-head a{display: inline-block;}
.onbloginwrapper .form-control{padding:15px;height:50px;}
.font-500{font-weight:500;}
.login-btn-home{float: left;width: 100%; padding: 15px 15px; color:#fff; border-radius: 2px; background-color:#1ea694; border: 0!important;font-size:16px; text-align: center; font-family: "MuliBold";margin-top: 10px;}
.login-btn-home:disabled{background-color:#ddd!important;cursor:not-allowed;}
.login-btn-home:hover{background-color: #61c0b4;color: #fff;text-decoration: none;}
.middle-text {background: none !important; border:none !important; margin-top: 20px; font-weight: bold; color: #000; font-size: 18;}
.middle {transition: .5s ease;opacity: 0;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);}
.contain {position: relative;width: 100%;}
.image { opacity: 1;display: block; width: 100%; height: auto;transition: .5s ease;backface-visibility: hidden;}
.middle {transition: .5s ease;opacity: 0;position: absolute;top: 50%;left: 50%; transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%)}
.contain:hover .image { opacity: 0.3;}
.contain:hover .middle {opacity: 1;}
.theme-show {float: left;position: fixed; background: #fff; z-index: 100; top:0; bottom: 0;left: 0;bottom: 0; right: 0; display: none;}
.theme-show-header {float: left; width: 100%;background: #eee; padding: 20px 30px;}
.middle-close { background-color: #000; color: #fff; border: none !important; width: 30px; height: 30px; font-size: 20px;margin-top:6px; }
.theme-show-design {  float: left;height: calc(100% - 120px);margin: 20px; overflow-y: auto;width: calc(100% - 40px);}
.theme-show-design img {max-width: 100%;}
.tvr-back-home {line-height:18px;float: left; width: auto; margin:0;position:absolute;font-size: 13px; color: #596377; font-weight: 400;left:30px;top:30px;background:#fff;z-index:10;}
.tvr-back-home i{margin-right:10px;font-size:16px;font-weight:bold;}
.tvr-right-main-wrapper{height:100%;overflow-y:auto;overflow-x:hidden;}
.tvr-right-main-outer{display:table;vertical-align:middle;height:100%;width:100%;}
.tvr-right-main-inner{padding:100px 0;display:table-cell;vertical-align:middle;position:relative;}
.cent-wrap-check{float: left;width: 100%;text-align: center;padding: 20px 0 0;font-weight: 400;font-size: 14px;color:#333;}
.cent-wrap-check a{display: inline-block;color:#124195;}
.log_wr_over{float: left;width: 100%;padding:30px;background-color: #fff;}
.log_wr_over_forgot{float: left;width: 100%;padding:30px 20px;background-color: #fff;border-radius: 0 0 4px 4px;}
.log_wr_over_telr{float: left;width: 100%;padding:30px 20px;background-color: #f5f5f7;border-radius: 0 0 4px 4px;text-align: center;}
.fgt_paswd{float: left;width: 100%;font-weight: 600;font-size: 18px;color: #000;padding:0 0 20px 0; text-align: left;}
.error_message{float: left;width: 100%;color: #ff0000;font-size: 12px;margin: 10px 0;text-align: center;}
.icons_left{position: absolute;right:0;top: 25%;}
.icons_left_exe{position: absolute;left:0;top: 25%;}
.icons_left_reg{position: absolute;right:0;top: 25%;}
.icons_left_cre{position: absolute;left:0;top: 25%;}
.inside_in_box{float: left;width: 100%;position: relative;margin:0 0 5px;}
.Header_logoBorder {border: 3px solid #f5f5f7;width: 70px;height: 70px;border-radius: 100%; box-shadow: 0 0 0 1px rgba(0,0,0,.18),0 2px 2px 0 rgba(0,0,0,.08);position: absolute;top:calc(0% - 35px); left: calc(50% - 35px);line-height: 60px;background-color: #fff;}
.autocomplete-root{float: left;width: 100%;position: relative;}
.autocomplete-dropdown-container{float: left;left:0;right:0;top:100%;position:absolute;background:#fcfcfc;list-style:none;padding:0px;margin:0;z-index: 100;max-height:120px;overflow:auto;}
.autocomplete-dropdown-container li{float: left;width:100%;font-size:15px;cursor:pointer;padding:6px 10px;}
.autocomplete-dropdown-container li .cityname1{color:#333;}
.autocomplete-dropdown-container li .cityname2{color:#888;}
.autocomplete-dropdown-container li:hover{background:#ececec;}
.error_message i {margin-right: 5px;}
.success_message {float: left;width: 100%;color:  #007F00;font-size: 12px;margin-bottom: 15px;text-align: center;}
.success_message i {margin-right: 5px;}
.center_wrap_overs{float: left;width: 100%; text-align: center;padding: 30px 0;}
.ord_name_top{float: left;width: 100%;color:  #434c5e;font-size: 14px; text-align: left;margin-bottom: 5px; text-transform: uppercase;font-weight: 700;letter-spacing: 0.8px;}
.ord_name_btm{float: left;width: 100%;color:  #434c5e;font-size: 12px; text-align: left; font-weight:500;}
.total_ord_value{float: left;width: 100%;text-align: center;padding:0px 0 20px 0;}
.total_ord_amt{border: 3px solid #f5f5f7;border-radius: 5px;box-shadow: 0 0 0 1px rgba(0,0,0,.18),0 2px 2px 0 rgba(0,0,0,.08);background-color: #fff;width: auto; height: 60px;display: inline-block;line-height: 50px;font-weight: 700;font-size: 18px;color: #333;padding: 0 15px;}
.pay_now_wrap_outer{float: left;width: 100%;}
.loaderIcon{padding:30px 0;text-align:center;float: left;width: 100%;}
#foloosiPluginApiiframe{border: 0;width:100%;height:100%;overflow-y: auto;}
#foloosiPluginApiiframe.frame-open{display:block;}
#foloosiPluginApiiframe.frame-close{display:none;}
.relative{position: relative;}
#foloosiPluginApiiframe body{background:none;}
.merch_identify{float: left;width: 100%;text-align: left;font-size: 24px;font-family: "MuliBold";color: #fff;margin-bottom: 20px;}
.amt_pait_ing{float: left;width: 100%;padding:10px 0; text-align: left;}
.ams_tct{display: inline-block;font-size: 16px;color: #434c5e;}
.amt_pais{float: left;width: 100%;font-size: 24px;color: #fff; text-align: left;font-family: "MuliBold";margin-bottom: 20px;}
.desc_identify{float: left;width: 100%;font-size: 14px;color: #333; padding:10px 20px;background-color: #f1f1f1;text-align: center;line-height: 24px;}
.create_namesw{float: left;width: 100%;font-family:"MuliRegular";font-size: 14px; text-align: left;color:#fff;margin-bottom:20px;}
.create_namesw_new{float: left;width: 100%;font-family:"MuliRegular";font-size:14px;text-align: left;color:#fff;margin-bottom:7px;}
.payforMonthy{float: left;width: 100%;font-family:"MuliRegular";font-size: 12px; text-align: left;color:#fff;margin-top: 5px;}
.payReffrom{float: left;width: 100%;font-family:"MuliRegular";font-size: 11px; text-align: left;color:#fff;}
.merDates{float: right;display: inline-block;font-family:"MuliRegular";font-size: 14px; text-align: right;color: #fff;}
.loginpage-tab{margin-bottom: 0px !important;float: left; text-align: center;width: 100%;}
.login-tabs li {padding: 0;float: left;margin: 0 !important;}
.login-tabs li a {background: none;padding: 0;border: none;display: inline-block;width: 100%;text-align: center;text-transform: uppercase;color: #444444;}
.login-tabs.nav-tabs li a {border-radius: none;color: #000;padding: 0;padding-bottom: 0px; float: left;border:0!important;}
.login-heads{float: left;width: 100%;background-color: #f9f9f9; color: #000;font-size: 18px; padding: 15px; border-top: 1px solid #e6e6e6;border-bottom: 1px solid #e6e6e6;font-weight: 500;}
.login-tabs{ border: none;}
.logincust {font-size: 13px; font-family: "MuliBold"; padding: 20px 24px;}
.cust_login_img {background: #e1e1e1 url("../../images/user-login-gray.png") no-repeat scroll 12px center;width: 100%;color: #646464;border-right: 1px solid #cccccc;}
.label-heads span{color:#000;font-size:20px;font-weight:500; text-transform: capitalize; margin: 0 15px;}
.login-tabs li.active a .cust_login_img, .login-tabs li:hover a .cust_login_img {background: #fff url("../../images/user-login.png") no-repeat scroll 12px center; color: #000;border-right: 1px solid #fff;}
.loginnurse {font-size: 13px; font-family: "MuliBold"; padding: 20px 24px;}
.pro_login_img {background: #e1e1e1 url("../../images/scan-gray.png") no-repeat scroll 15px center;width: 100%;color: #646464;border-right: 1px solid #cccccc;}
.login-tabs li.active a .pro_login_img, .login-tabs li:hover a .pro_login_img {background: #fff url("../../images/scan-pay.png") no-repeat scroll 15px center;color: #000;border-right: 1px solid #fff;}
.guest_login_img {background: #e1e1e1 url("../../images/guest-gray.png") no-repeat scroll 20px center;width: 100%;color: #646464;border-right: 1px solid #cccccc;}
.login-tabs li.active a .guest_login_img, .login-tabs li:hover a .guest_login_img {background: #fff url("../../images/guest-login.png") no-repeat scroll 20px center;color: #000;border-right: 1px solid #fff;}
.qr_wrapd{float: left;width: 100%; text-align: center;}
.left_po_ier{float: left;width: 80px;}
.ler_powe{float: left;width:100%;}
.accepted_card_pay{float: left;width: 100%;}
.col_erc{float: left;width: 100%;padding:0 10px;}
.paycard_info{float: left;width: 100%;padding: 0;margin: 0;list-style-type: none;text-align: center;}
.paycard_info li{display: inline-block;margin-right: 0px;}
.right_wep{float: left;width: 100%; text-align: center;margin-top: 0px;padding:0 25px;}
.right_wep a{display: inline-block;}
.payodd_txt{float: left;width: 100%;font-size: 12px; font-family:"MuliRegular";color: #646464;text-align: center;margin-top:15px;}
@media screen and (max-width: 767px) {
	.infoWarningTextOne,.infoWarningTextTwo{font-size:11px!important;}
	.logincust,.loginnurse {padding:20px 15px 15px;}
	.modalOuterWraping{width: auto!important;}
	.cust_login_img {background: #e1e1e1 url("../../images/user-login-gray.png") no-repeat top center;width: 100%;color: #646464;border-right: 1px solid #cccccc;}
	.pro_login_img {background: #e1e1e1 url("../../images/scan-gray.png") no-repeat top center;width: 100%;color: #646464;border-right: 1px solid #cccccc;}
	.guest_login_img {background: #e1e1e1 url("../../images/guest-gray.png") no-repeat top center;width: 100%;color: #646464;border-right: 1px solid #cccccc;}
	.login-tabs li{padding:15px 0 0!important;}
	.login-tabs li{background: #e1e1e1;}
	.login-tabs li.active,.login-tabs li:hover{background: #fff;}
	.login-tabs li.active a .cust_login_img, .login-tabs li:hover a .cust_login_img {background: #fff url("../../images/user-login.png") no-repeat top center; color: #000;border-right: 1px solid #fff;}
	.login-tabs li.active a .pro_login_img, .login-tabs li:hover a .pro_login_img {background: #fff url("../../images/scan-pay.png") no-repeat top center;color: #000;border-right: 1px solid #fff;}
	.login-tabs li.active a .guest_login_img, .login-tabs li:hover a .guest_login_img {background: #fff url("../../images/guest-login.png") no-repeat top center;color: #000;border-right: 1px solid #fff;}
	.right_wep{float: left;width: 100%;text-align: center;margin-top: 10px;}
	.paycard_info{float: left;text-align: center;}
	.logincust, .loginnurse{font-size: 13px!important;}
	img{max-width:100%;}
	.no-padding-xs{padding: 0!important;}
	.left_po_ier{width: 60px!important;margin-top: 6px!important;}
	.left_po_ier img{width: 50px !important;height: auto!important;}
	.tvr-login-head{padding: 12px!important;}
	.merch_identify{font-size: 16px!important;line-height: 20px!important;}
	.create_namesw{font-size: 14px!important;padding: 2px 0 0!important;}
	.create_namesw_new{font-size: 14px!important;padding: 2px 0 0!important;}
	.amt_pais{font-size: 18px!important;}
	.loginpage-tab{padding: 0!important;}
	.footer_wrapper{position: static!important;}
	.accepted_card_pay{box-shadow: none !important;}
	.desc_identify{padding: 10px!important;}
	.icons_left{right:0!important;bottom: 15%!important;}
	.payodd_txt{padding: 10px 0px 5px 0!important;text-align: center!important;}
	.wrapper-login{padding-bottom: 0px!important;}
	.tvr-main-wrapper{padding:0!important;}
	.tvr-login-wrap {border: 0!important;}
	.icons_left_exe{left:0!important;font-size:14px;}
	.log_wr_over{padding: 25px!important;}
	.icons_left_gst{right: 0!important;}
	.infoWarningTextOne{text-align: left;}
}
@media (min-width: 360px) and (max-width: 767px) {
	.icons_left_reg{left:5%!important;}
	.icons_left_cre{left:12%!important;}
}
@media (min-width: 768px) and (max-width: 991px) {
	.tvr-input_num{padding-left:90px!important;}
	.icons_left_reg{left:6%!important;}
	.icons_left_cre{left:14%!important;}
	.tvr-input_regt{padding-left:80px;}
}
.loginpage-tab .tabbtn{float: left;}
.pay_now_wrap_outer{float: left;width: 100%;}
.loaderIcon{padding:30px 0;text-align:center;float: left;width: 100%;}
#foloosiPluginApiiframe.frame-open{display:block;}
#foloosiPluginApiiframe.frame-close{display:none;}
.relative{position: relative;}
#foloosiPluginApiiframe body{background:none;}
.paymennInfoTop {float: left;width: 100%;padding: 7px 20px;text-align: center;background: #1755c3;color: #fff;font-size: 20px;position: absolute;top: 0;z-index: 100;font-weight: 700;display:none!important;}
.payment_wrapper{min-height:355px;}
.wrapper_content{background-color: #fff;}
.qr_wrapd img {max-height: 202px;}
.successdirectimg{height:100px;}
.footer_wrapper{float: left;width: 100%;background: #f6f6f6;padding:15px 0;border: 1px solid #e9e9e9;border-top:0;}
.wrapper-login{padding-bottom:20px;}
.successPayimg{height:95px;}
.mobileapp{float: left;padding: 7px 0; background-color: #333333;width: 100%;text-align:center;}
.app_close{color: #fff;font-size: 15px; vertical-align: middle; margin-right: 20px; display: inline-block;cursor:pointer;}
.logo-img-app{display:block; vertical-align: top;}
.logo-img-app .logoinstall{margin-right: 20px; display: inline-block;}
.logo-img-app img{width: 36px; height: 36px; display: inline-block;border-radius:5px;}
.app-cont{font-size: 12px;color: #fff; display: inline-block; text-align: left; vertical-align: top; margin-right: 20px; margin-top: 1px;}
.app-cont p {color: #ffffff;font-size: 11px;line-height: 15px;margin:0;}
.app-get{margin-top: 1.5px; border: 1px solid #fff; color: #fff; font-size: 12px; padding: 5px 10px; border-radius: 3px; cursor: pointer; display: inline-block;cursor:pointer;}
.app-get:focus,.app-get:hover{color:#fff!important;}
.logoinstall{background: url('../../images/applogo.png') no-repeat center / 100% 100%;height:36px;width:36px;display: inline-block;vertical-align:top;}
input[type='number'] {
    -moz-appearance:textfield;
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.loading_text_contFirst{font-size:25px;color:#000;margin:30px 0 0;font-family:'Poppins', sans-serif;font-weight:700;}
.loading_text_cont{font-size:13px;color:#555;margin:20px 0 0;font-family:'Poppins', sans-serif;}
.loading_image_wrapper{position: fixed;left: 0;top: 0;width: 100%;height: 100%;z-index: 10000000001;text-align: center;background: #f1f1f1;opacity: 1;visibility: visible;-webkit-transition: opacity 0.4s,visibility -0.3s linear 0.5s;transition: opacity 0.4s,visibility -0.3s linear 0.5s;}
.loading_image_inner{position: absolute;top: 50%;left: 50%;-webkit-transform: translate(-50%,-50%);-ms-transform: translate(-50%,-50%);transform: translate(-50%,-50%);}
.infoWarningTextOne{float: left;width: 100%;font-size:12px;color:#969696;margin:5px 0 0px 0;font-family: "MuliRegular";}
.infoWarningTextTwo{float: left;width: 100%;font-size:12px;color:#ff0000;margin:15px 0 0;font-family:'Poppins', sans-serif;text-align: left;}
.infoWarningTextTwo i{margin-right: 5px;}
.infoWarningTextOne i{margin-right: 3px;}
.leftWrapContPhone{float: left;width:100px;position: relative;border-top: 0;border-right: 0;border-left: 0;border-bottom: 1px solid #dbdbdb;background: #fff;height: 45px;}
.leftWrapContPhone:after{background-color: #cfcfcf;height: 15px;width: 2px;content: "";position: absolute;top: 16px;right: 0;}
.rightWrapContPhone{float: right;width: calc(100% - 100px);position: relative;}
.countryFlags{padding: 0;margin: 0;list-style-type:none;float: left;width: 100%;}
.down-arrow-to{top: 6px;position: relative;left: 20px;width: 0;height: 0;border-left: 4px solid transparent;border-right: 4px solid transparent;border-top: 4px solid black;}
.tvr-input_num_gst{color:#000!important;font-size:14px;font-family: "MuliRegular";height: 45px; padding-left: 10px;border-radius: 0;box-shadow: none;padding-right: 0;border-top: 0;border-right: 0;border-left: 0;border-bottom: 1px solid #dbdbdb;}
.tvr-input_num_gst:focus{box-shadow: none;border-color:#dbdbdb;}
.icons_left_gst{position: absolute;right:0;top: 25%;}
.country_flag{float: left;width:46px;height: 100%;}
.country_flag .selected-flag {z-index: 1;position: relative;width: 46px;height: 100%;padding: 0 0 0 8px;}
.selected-flag .iti-flag {position: absolute;top: 0;bottom: 0;margin: auto;}
.country_flag .selected-flag .iti-arrow{position:absolute;top:50%;margin-top:-2px;right:6px;width:0;height:0;border-left:3px solid transparent;border-right:3px solid transparent;border-top:4px solid #555}
.country_flag .selected-flag .iti-arrow.up{border-top:none;border-bottom:4px solid #555}
.phone_code {line-height:43px;padding: 0 2px;float:left;width:52px;text-align: center;}
.leftWrapContPhone .country-list {
position: absolute;
z-index: 2;
list-style: none;
text-align: left;
padding: 0;
margin: 0 0 0 -1px;
box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
background-color: white;
border: 1px solid #CCC;
max-height: 220px;
overflow-y: scroll;
bottom:100%;
min-width:280px;
}

.leftWrapContPhone .country-list.dropup {
bottom: 100%;
margin-bottom: -1px
}

.leftWrapContPhone .country-list .flag-box {
display: inline-block;
width: 20px
}

.leftWrapContPhone .country-list .divider {
padding-bottom: 5px;
margin-bottom: 5px;
border-bottom: 1px solid #CCC
}

.leftWrapContPhone .country-list .country {
padding: 5px 10px
}

.leftWrapContPhone .country-list .country .dial-code {
color: #999
}

.leftWrapContPhone .country-list .country.highlight {
background-color: rgba(0, 0, 0, 0.05)
}

.leftWrapContPhone .country-list .flag-box,
.leftWrapContPhone .country-list .country-name,
.leftWrapContPhone .country-list .dial-code {
vertical-align: middle
}

.leftWrapContPhone .country-list .flag-box,
.leftWrapContPhone .country-list .country-name {
margin-right: 6px
}
.country-list li{cursor: pointer;}
.country-list li:hover {
	background-color:#ececec;
}
.modalOuterWraping{width:450px;margin: 0 auto;}
.drMargik img{margin-right: 5px;}
.qrcodeInformatio{float: left;width: 100%;color: #969696;font-family: 'MuliRegular';font-size: 14px;text-align: center;margin-bottom: 25px;}
.error_message_bottom {position: absolute;top: 100%;font-size: 11px;color: #f00;left:0px;z-index:10;line-height: 20px;}
.foloosiPluginApiiframeWrapper{float: left;width:calc(100% - 20px);-webkit-overflow-scrolling: touch;margin: 0 10px;position: relative;}
.frame-open{display:block;}
.frame-close{display:none;}
.loaderIconWrap{float: left;width:100%;background: #fff;}
.forgot_password_link {color: #969696;cursor: pointer;font-size: 11px;font-weight: normal;text-transform: none;transition: color 0.2s ease 0s;float:right;}
.center_align{display: inline-block; width:100%;padding: 70px;}
.redtitle{color:#f54443; font-size:20px;font-weight: 600;margin-top: 16px;text-align: center;line-height: 20px;}
.detaildesc{font-size: 15px;color:#777777;margin-top: 16px;text-align: center;line-height: 20px; text-align: center;font-weight: 400;}
.center_wrap_overs h1 {font-size: 23px;}
.paycard_info_bottom{float: left;padding: 0;margin:10px 0 0;list-style-type: none;width:100%;border-top:1px solid #e1e1e1;padding-top:10px;}
.paycard_info_bottom li.align_left{margin-right: 0px;float: left;}
.paycard_info_bottom li.align_right{margin-right: 0px;float: right;}
.paycard_info_bottom li img{height:30px;}
.paycard_info li img{height:30px;}

.pay_popup_section_form_card {border: 1px solid #eee;padding: 20px;float: left;width: 100%;border-radius: 10px;}
.pay_popup_section_field {float: left;width: 100%;margin: 0 0 15px;position: relative;}
.pay_popup_card_label {float: left;width: 100%;font-size: 12px;color: var(--main-primary-color);margin: 0 0 5px;font-weight: 600;}
.pay_popup_card_input {float: left;width: 100%;font-size: 13px;color: #333;padding: 7px 0;border: 0;border-bottom: 1px solid #e1e1e1;}
.pay_popup_section_field_inner {float: left;width: 37.5%;margin: 0 0 15px;position: relative;}
.p-lr-20 {padding-left: 20px;padding-right: 20px;}
.error_border{border-color: red;}
.pay_popup_section_field_cvv {float: left;width:95px;margin: 0 0 15px;position: relative;}
.pay_popup_submit_disable {float: left;width: 100%;padding: 10px 15px;border-radius: 5px;font-weight: 500;border: 0;margin: 15px 0 0;text-align: center;background:#195dd4;font-size: 18px;color:#fff;cursor:pointer; }
.pay_popup_submit_disable:hover {text-decoration: none;color: #fff;}
.text-right{text-align: right;}
.pay_popup_section{float: left;width: 100%;padding:20px 5px;background-color: #fff;}
.pay_popup_section_form{float: left;width: 100%;}
.pay_popup_section_field_city {float: left;width:25%;margin: 0 0 15px;position: relative;}
.pay_popup_section_field .pay_popup_section_field_address:nth-child(2n+1) {padding-right: 10px;}
.pay_popup_section_field_address {float: left;width: 50%;margin: 0 0 15px;position: relative;}
.pay_popup_section_field .pay_popup_section_field_address:nth-child(2n) {padding-left: 10px;}
.pay_pop_card_img {position: absolute;right: 0;top: -10px;width: 60px;height: 30px;float: left;background-size: 100% 100%;}
.plr-15{padding:0 15px;}
.pay_popup_submit{float: left;width: 100%;background:#195dd4;padding:10px 15px;font-size:16px;border-radius:5px;font-weight:500;cursor: pointer;border:0;color:#fff;margin:15px 0 0;text-align: center;}
.pay_popup_section_field_expiry {float: left;width: 25%;margin: 0 0 15px;position: relative;}
.pay_popup_section_field_country {float: left;width: calc(75% - 95px);margin: 0 0 15px;position: relative;}
#foloosiPluginApiiframe{min-height:400px!important;}
.pay_popup_section input:focus,.pay_popup_section select:focus {outline: 0;}
.pay_popup_card_country svg{width:15px!important;color:rgb(204, 204, 204);}
.error_message_top{float: left;width: 100%;color: #ff0000;font-size: 12px;margin:0 0 10px;text-align: center;}
.pay_popup_section_field_state{float: left;width:50%;margin: 0 0 15px;position: relative;}
.tvr-head-logo {position: absolute;left: calc(50% - 50px);top: -50px;z-index: 10;float: left;width: 100px;height: 100px;border-radius: 5px;background: #fff;padding: 3px;text-align: center;border: 2px solid #eee;}
.tvr-head-logo img {max-height: 94px;max-width: 86px;object-fit: cover;border-radius: 5px;}
.table_logo_align{display: table;width: 100%;height:100%;margin: 0;padding: 0;}
.center_img_logo{display: table-cell;vertical-align: middle;text-align: center;margin: 0;padding: 0;}
.margin_top_align_logo{margin-top: 50px;}
.m-t-50{margin-top:50px!important;}
.error_message_bottom_new {font-size: 11px;color: #f00;margin:3px 0 0;float: left;width: 100%;}
.pay_popup_card_input.pay_popup_card_country_error,.pay_popup_card_country_error{border-bottom: 1px solid red;}

.done_button{background:#195dd4;padding:5px 15px;font-size:16px;border-radius:3px;font-weight:500;cursor: pointer;display: inline-block;color:#fff;margin:25px 0 5px;}
.cancel_button{background:#FF4131;padding:5px 15px;font-size:16px;border-radius:3px;font-weight:500;cursor: pointer;display: inline-block;color:#fff;margin:25px 0 5px;}
.transactionPay{font-size:16px;margin:25px 0 0;font-weight:400;color:#666;}
.transactionPayid{font-size:16px;margin:15px 0 0;font-weight:400;color:#666;}

.paymode_section{float: left;;width: 100%;text-align: center;padding: 50px 0;}
.paymode_section_split{float: left;width: 100%;font-size: 16px;font-weight: 500;color: #d2cfcf;padding: 20px 0;position: relative;}
.paymode_section_split::before{position: absolute;top: calc(50% + 1px);left: calc(50% - 80px);right: calc(50% + 22px);background: #d2cfcf;content: "";height: 1px;}
.paymode_section_split::after{position: absolute;top: calc(50% + 1px);right: calc(50% - 80px);left: calc(50% + 22px);background: #d2cfcf;content: "";height: 1px;}
.paymode_section_btn_warp a{display: inline-block;cursor: pointer;}
.paymode_section_btn_warp{display: inline-block;width: 100%;}
.paymode_section_credit{padding:8px 15px;font-size: 15px;font-weight: 600;color: #fff;background: #195dd4;min-width: 212px;min-height: 38px;border-radius: 3px;}
.paymode_section_credit:hover{color: #fff;background: #195dd4;text-decoration: none;}
.total_po_wr {float: left;width: 100%;padding: 25px !important;}
.plan_top_merchant {float: left;width: 100%;color: #333;font-family: MuliRegular;font-size: 15px;margin: 0 0 15px;position: relative;
padding: 0 0 0 25px;line-height: 22px;}
.plan_top_merchant i {font-size: 15px;position: absolute;left: 0;color: #777;line-height: 22px;}
.login-btn-home {float: left;width: 100%;padding: 15px;color: #fff;border-radius: 2px;background-color: #1ea694;border: 0 !important;
font-size: 16px;text-align: center;font-family: MuliBold;margin-top: 30px;}